import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Section, Columns } from "../components/Containers"
import { Image } from "../components/Core"
import { Link } from "gatsby"

const Refer = () => {
  return (
    <Layout navSpacer pageTitle="plymouth-page" language={"en"}>
      <SEO
        lang="en"
        title="Wisdom Teeth Treatment for Plymouth, MN | Wise Decision"
        description="Wise Decision provides expert wisdom teeth removal in Plymouth, MN. Trust Dr. Kirby Johnson for skilled care. Schedule your consultation today."
      />

      <div className="sjl relative">
        <Section zeroBottomDesktop>
          <Columns>
            <div className="column is-2"></div>
            <div className="column">
              <div className="sjl__hero">
                <div className="sjl__hero--content px-40--mobile">
                  <h1>Wisdom Teeth Removal for Plymouth, MN</h1>

                  <div className="sjl__hero--tags">
                    <div>
                      <h2 style={{ fontWeight: "normal", fontSize: "30px" }}>
                        Hey Moms and Dads!
                        <br />
                        Is your teen ready for wisdom teeth removal?🦷
                      </h2>
                    </div>
                  </div>
                </div>

                <Image
                  wrapperClassName="sjl__hero--image"
                  publicId="WOD/DEV/wisdom-teeth-consult-hero-2"
                />
              </div>
            </div>
          </Columns>
        </Section>

        <Section colorBack className="sjl__requirements rounded-top">
          <Columns sideColumnsSize={2}>
            <div className="column px-40--mobile">
              <p>
                Give your 16-20 year olds exceptional care at our unique,
                specialized wisdom teeth surgery center. Our team of experienced
                oral surgeons and physician anesthesiologists ensure a safe,
                comfortable procedure.
              </p>

              <ul
                className="mt-3"
                style={{ listStyle: "none", marginInline: 0, padding: 0 }}>
                <li>✅ Expert oral surgeons</li>
                <li>✅ Dedicated medical doctor anesthesiologists</li>
                <li>✅ State-of-the-art facility</li>
                <li>✅ Tailored care for teens and young adults</li>
              </ul>

              <p>
                Don’t wait!{" "}
                <Link to="/contact-us/" title="Contact our office">
                  Schedule a consultation
                </Link>{" "}
                today and give your child the gift of a healthy, pain-free
                smile.😊
              </p>

              <h3>Trustworthy Wisdom Teeth Removal Near Plymouth, MN</h3>

              <p>
                Wisdom teeth, also known as third molars, often cause
                difficulties as they develop. Numerous individuals experience
                challenges like overcrowding, discomfort, infection, or possible
                harm to adjacent teeth. If ignored, these issues can escalate
                into more serious dental complications. Removing wisdom teeth
                can prevent these complications, promoting long-term oral health
                and comfort.
              </p>
              <p>
                Dr. Johnson’s extensive experience and commitment to precision
                make him an unmatched choice for wisdom teeth removal. At Wise
                Decision, we go beyond offering excellent technical care by
                incorporating the expertise of an on-site, Pediatric
                Anesthesiologist MD for a seamless, safe, and pain-free
                experience. Our state-of-the-art practice ensures patients
                receive the highest standard of treatment in a relaxing and
                professional environment.
              </p>
              <p>
                Don’t wait until wisdom teeth become a major problem.{" "}
                <Link to="/contact-us/" title="Contact our office">
                  Call
                </Link>{" "}
                Wise Decision today to schedule your consultation and trust the
                experienced care of Kirby Johnson, DDS. We’re here to make your
                wisdom teeth removal a smooth and stress-free process. Your path
                to optimal oral health begins here.
              </p>
            </div>
          </Columns>
        </Section>
      </div>
    </Layout>
  )
}

export default Refer
